<template>
  <div class="speech-to-text-notes">
    <h3>{{ computedNotesLabel }}</h3>
    <div class="input-container">
      <b-form-textarea
        id="additional_notes"
        v-model="notes"
        :placeholder="staticText.notesPlaceholder"
        :disabled="disabled"
        rows="2"
        max-rows="4"
        @blur="$emit('save-notes')"
      />
      <div class="microphone-container">
        <div class="microphone-container2">
          <speech-to-text
            :disabled="disabled"
            :should-save="true"
            @transcript="handleTranscript"
            @save="$emit('save-notes')"
            class="custom-mic"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SpeechToText from "@/components/SpeechToText"

export default {
  name: "SpeechToTextNotes",
  components: {
    SpeechToText
  },
  data () {
    return {
      notes: this.value,
      staticTextDefault: {
        notesLabel: "Notes",
        notesPlaceholder: "Type or speak your notes here"
      }
    }
  },
  props: {
    value: {
      type: String,
      default: "",
      required: false
    },
    notesLabel: {
      type: String,
      default: "",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    computedNotesLabel () {
      return this.notesLabel ? this.notesLabel : this.staticText.notesLabel
    }
  },
  watch: {
    notes (newNote) {
      this.$emit("note-changed", newNote)
    }
  },
  methods: {
    handleTranscript (transcript) {
      document.getElementById("additional_notes").focus()
      this.notes = this.notes ? `${this.notes} ${transcript}` : transcript
    }
  },
  beforeDestroy () {
    this.$emit("save-notes")
  }
}
</script>

<style lang="scss" scoped>
.speech-to-text-notes {
  border-top: 1px solid $black06;
  padding: 8px;
  position: relative;
  @include media-breakpoint-up(md) {
    padding: 8px 16px 16px;
  }
  h3 {
    font-size: 12px;
    line-height: 13px;
    font-weight: 700;
    color: $slate;
    margin-bottom: 8px;
    @include media-breakpoint-up(md) {
      font-size: 16px !important;
      line-height: 17px;
    }
  }

  .input-container {
    position: relative;
  }

  textarea {
    padding: 16px 34px 16px 16px;

    &:disabled {
      background-color: rgba(255, 255, 255, 0.4);
      color: rgba(127, 129, 151, 1);
    }
  }

  $big-size: 32px;
  $small-size: 24px;
  .microphone-container {
    position: absolute;
    height: $small-size;
    width: $small-size;
    right: 8px;
    bottom: 8px;
  }
  .microphone-container2 {
    position: relative;
    height: 100%;
    width: 100%;
  }

  :deep(.custom-mic) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    .mic-button {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      padding: 0;
      transition: 0.3s ease-in;

      svg {
        width: 16px;
        height: 16px;
        path {
          transition: 0.3s ease-in;
        }
      }

      &.active {
        @keyframes pulsating {
          50% {
            transform: scale(1.2);
          }
        }
      }

      &:hover {
        background-color: $slate10;
        svg {
          path {
            stroke: $slate;
          }
          path:first-child {
            fill: $slate;
          }
        }
      }
    }
  }
}
</style>
